import React, { useEffect } from 'react';
import firebase from '../../firebase/firebase';
import { Box, Button, Typography } from '@mui/material';
import {CoverSectionWrapper } from './styled-components';
import { iusers } from '../../interface/iusers';
import { convertObjToArray } from '../../helper/object';
import { dataList } from '../../assets/data/data';
import uuid from 'react-uuid';

type Props = {
    setIsOpened: any,
    guest: iusers | undefined,
    audioRef: any,
    isPlayingMusic: any,
    setIsPlayingMusic: any,
    isExpandedMusic: boolean
    setIsExpandedMusic: any,
    onHoverMusic: boolean
}

export const CoverSection: React.FC<Props> = ({ setIsOpened, guest, audioRef, isPlayingMusic, setIsPlayingMusic, isExpandedMusic, setIsExpandedMusic, onHoverMusic }) => {
    const dataRefUser = firebase.ref('/users');

    const AddInvitation = async (obj: iusers) => {
      const result = await dataRefUser.push(obj);

      const addedUser = firebase.ref(`/users/${result.key}`)
      addedUser.on('value', (snapshot) => {
        const dataSnapshot = snapshot.val();
        
        addedUser.update({
          ...dataSnapshot,
          id: uuid()
        })
      });
    }

    const getAllInvitation = () => {
      dataRefUser.orderByChild('id').equalTo('137957b4-c96f-f2d6-b697-8c214e530fc1').on('value', (snapshot) => {
        const dataSnapshot = snapshot.val();

        console.log(dataSnapshot)
        
        // const objToArray: any = convertObjToArray(dataSnapshot)
        // const sorted = objToArray.toSorted((a: any, b: any) => {
        //   if (a.name < b.name) {
        //     return -1;
        //   }
        //   if (a.name > b.name) {
        //     return 1;
        //   }
        //   return 0;
        // })

        // sorted.forEach((data: iusers) => {
        //   console.log(`${data.name} : https://selangkah.fun/${data.id}`)
        // })
      });
    }

    useEffect(() => {
        if(isExpandedMusic && !onHoverMusic) {
            setTimeout(() => {
                setIsExpandedMusic(false)
            }, 5000);
        }

        console.log(guest)
    }, [isExpandedMusic])

    return <CoverSectionWrapper>
        <Box className="d-flex align-items-center justify-content-center position-absolute w-100 h-100 text-light">
            <Box>
                {/* <img src={SALogo} width={125} className='initial-logo'/> */}
                <Typography className='molde-condensed-bold-italic animate-text-focus-in-1s' color='#fff' fontSize={{ xs: '2.25rem', md: '3rem' }} fontStyle='italic'>SELARAS & ANGGA</Typography>
                <Typography className='my-4 text-center animate-text-focus-in-2s' fontSize={{ xs: '1rem', md: '1.3rem' }} fontWeight={600}>#SELANGKAHtilljannah</Typography>
                <Typography className='my-4 font-weight-bold text-center animate-text-focus-in-3s' fontSize={{ xs: '1.2rem', md: '1.3rem' }} fontWeight={600}>27 . 01 . 2024</Typography>
            </Box>
        </Box>
        {
            guest && guest.id && 
            <>
                <Box className='d-flex align-items-end justify-content-center position-absolute w-100 h-100' style={{ paddingBottom: '6.5rem' }}>
                    <Typography textAlign='center' color='#fff' className='animate-text-focus-in-1s'>Berlaku untuk {guest.invitedPeople} orang</Typography>     
                </Box>
                <Box className='d-flex align-items-end justify-content-center position-absolute w-100 h-100 pb-5'>
                  {/* <Button className='bg-light text-dark puff-in-center-1s'  onClick={async () => {
                    dataList.forEach(data => {
                      AddInvitation(data)
                    })
                  }}>Tambah Undangan</Button> */}
                  {/* <Button className='bg-light text-dark puff-in-center-1s'  onClick={async () => {
                    getAllInvitation()
                  }}>Get All Undangan</Button> */}

                  <Button 
                      className='bg-light text-dark puff-in-center-1s' 
                      onClick={() => {
                          setIsOpened(true)
                          if (audioRef.current && !isPlayingMusic) {
                              audioRef.current.play();
                              setIsPlayingMusic(true);
                              setIsExpandedMusic(true)
                          }
                      }}
                  >
                      Buka Undangan
                  </Button>
                </Box>       
            </>
        }

    </CoverSectionWrapper>
}